<template>
  <v-main>
    <v-container>
      <div>
        Versão: {{ appVersion }}
        <!--<v-btn class="float-right" x-small color="error" @click.stop="forceUpdate()">Atualizar</v-btn>-->
      </div>
    </v-container>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Acessar Rota</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  label="Código do Funcionario"
                  v-model="funcionario"
                  name="funcionario"
                  type="number"
                ></v-text-field>
                <v-text-field
                  label="Senha"
                  v-model="senha"
                  name="funcionario"
                  type="password"
                ></v-text-field>
                <v-select
                  :items="caminhoes"
                  item-text="matricula"
                  label="Matricula do Carro"
                  v-model="caminhao"
                  item-value="matricula"
                  solo
                  v-if="caminhoes != ''"
                  v-on:change="changeMatricula"
                  return-object
                ></v-select>
                <v-select
                  :items="tipoCircuitos"
                  item-text="circuito"
                  label="Circuitos"
                  v-model="circuito"
                  solo
                  v-if="caminhao != ''"
                  return-object
                ></v-select>
                <v-text-field
                  label="Quilometragem Inicial"
                  v-model="km_inicial.km"
                  name="km_inicial"
                  v-if="caminhao != ''"
                  type="number"
                  :rules="rules"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="logar()" type="submit">
                Login
              </v-btn>
              <v-btn
                color="success"
                @click="acessar()"
                :disabled="km_inicial.km < caminhao.kmlast || !circuito">
                Entrar
              </v-btn>
            </v-card-actions>
            <v-alert type="error" v-if="error">{{ error }}</v-alert>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
</template>
<script>
/* eslint-disable */
//import { contentores } from '../App.vue';

import {version} from '../../package'
import mixin from '../plugins/mixin';

export default {
  name: "Login",
  mixins: [mixin],

  data: () => ({
    appVersion: version,
    user: { identifier: "", password: "" },
    token: "",
    funcionario: "",
    senha: "",
    caminhoes: [],
    tipoCircuitos: [],
    circuito: null,
    contentors: [],
    caminhao: "",
    error: "",
    km_inicial: { km: "", tipo: "inicio", caminhao: null, user: null },
    /*********** */
    contentorsFiltered: [],
    readyToGo: false,
  }),
  mounted() {
    if (this.getObjeto("contentorsFiltered")) this.$router.push("/contentores");
  },
  methods: {
    logar() {
      this.error = "";
      this.$http
        .post("auth/local", {
          identifier: this.funcionario,
          password: this.senha,
        })
        .then((response) => {
          if (
            response.data.user.role.name == "Administrador" ||
            response.data.user.role.name == "Motorista"
          ) {
            this.user = response.data.user;
            this.token = response.data.jwt;
            this.listaDeCamioes();
            this.listaDeCircuitos();
            this.listaAgendamentos();
          } else this.error = "Utilizador sem permissão.";
        })
        .catch((error) => {
          this.error = "Código incorreto";
          console.log("An error occurred:", error);
        });
    },
    listaDeCircuitos() {
      this.listaContentores();
      this.$http
        .get("tipo-circuitos", {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.tipoCircuitos = response.data;
        });
    },
    listaContentores() {
      this.$http
        .get("contentors?_limit=10000", {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.contentors = response.data;
          this.contentors.forEach((item) => {
            item.autocomplete =
              item.numero_contentor +
              " - " +
              item.rua +
              " - " +
              (item.referencia ? item.referencia + " - " : "") +
              item.localidade.designacao;
          });
          //this.contentors = response.data;
        });
    },
    listaDeCamioes() {
      this.$http
        .get("camioes/lastKm", {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {

          this.caminhoes = response.data.rows;
        });
    },
    listaAgendamentos(){
      this.$http
        .get('graphql?query={ \
          agendamentos (where: { _or: [{recolhido: false}, {recolhido_null: true}] cancelado: false}) { \
            id, \
            nome, \
            morada, \
            contacto, \
            recolhido, \
            obsAgendamento, \
            dataHoraRecolha, \
            dataHoraAgendamento, \
            obsRecolha, \
            freguesia {id, designacao}, \
          } \
        }', {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.setObjeto("agendamentos", response.data.data.agendamentos);
        });
    },
    acessar() {
      this.setObjeto("user", this.user);
      this.setObjeto("token", this.token);
      this.setObjeto("caminhao", this.caminhao);
      this.setObjeto("tipoCircuito", this.circuito.tipoDeRecolha);

      if (this.circuito.tipoDeRecolha == "clean" ||
          this.circuito.tipoDeRecolha == "maintenance" ||
          this.circuito.tipoDeRecolha == "monsters") {

        switch(this.circuito.tipoDeRecolha){
          case 'clean':
            var endpoint = "contentors/needClean/1";
            break;
          case 'maintenance':
            var endpoint = "contentors/needMaintenance/1";
            break;
          case 'monsters':
            var endpoint = "contentors/haveMonster/1";
            break;
        }

        this.$http.get(endpoint, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }).then((response) => {
            var resp = response.data;
            //to filter the containers on the response from all the containers (containing all the info)
            var contentorsFiltered = this.contentors.filter(function (obj1) {
              return resp.some(function(obj2){
                return obj1.id == obj2.contentor;
              })
            });

            //filter based on name! change to IDs on the future
            var freguesias = contentorsFiltered.map(item => item.localidade.designacao)
                                                .sort((a,b) => a < b ? -1 : (a > b ? 1 : 0)) //to sort
                                                .reduce(function(prev, cur) {
                                                  prev[cur] = (prev[cur] || 0) + 1;
                                                  return prev;
                                                }, {});
            /*
            var freguesias = contentorsFiltered.map(item => item.freguesia)
                                            //.reduce((prev, cur) => { !prev[cur[key]] ? prev[cur[key]] = 1 : prev[cur[key]] += 1 })
                                            .sort((a,b) => a < b ? -1 : (a > b ? 1 : 0)); //to sort
            freguesias = freguesias.map((item, i) => ({id: i+1, freguesia: item})); //to make index
            */
            freguesias = Object.keys(freguesias).map((k, i) => ({id: i+1, freguesia: k, count: freguesias[k]}));
            freguesias.unshift({id: -1, freguesia:"Todas", count: contentorsFiltered.length}); // add "all" to top
            this.setObjeto("freguesias", freguesias);
            this.setObjeto("freguesiaSelecionada", -1);
            this.setObjeto("contentorsFiltered", contentorsFiltered);
            this.readyToGo = true;
          }).catch((error) => {
            // Handle error.
            console.log("An error occurred:", error);
        });
      } else {
        this.$http
          .get("rotas?tipo_circuito=" + this.circuito.id, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          })
          .then((response) => {
            var rotas = response.data;
            this.setObjeto("rotas", rotas);

            if (this.circuito.id == 20){
              //BIG NAIL!!!! Specific for PAYT Circuit
              let rota = rotas[0];
              this.setObjeto("rotaSelecionada", rota.id);
              this.readyToGo = true;
            } else {
              var diaDaSemanaHoje = new Date().getDay();
              let rota = rotas.find((rota) => {
                return rota.diaDaSemana == diaDaSemanaHoje
              });
              if (rota){
                this.setObjeto("rotaSelecionada", rota.id);
                //to filter the containers on the response from all the containers (containing all the info)
                var contentorsFiltered = this.contentors.filter(function (obj1) {
                  return rota.Contentors.some(function(obj2){
                    return obj1.id == obj2.contentor.id;
                  })
                });
                this.setObjeto("contentorsFiltered", contentorsFiltered);
                this.readyToGo = true;
              } else {
                this.setObjeto("rotas", null);
                this.error = "Não existe rota neste Circuito para a data de hoje!";
                console.log("Não existe rota neste Circuito para a data de hoje!");
              }
            }
        });
      }
    },
    changeMatricula(){
      this.km_inicial.km = this.caminhao.kmlast;
    },
  },
  created() {

  },
  computed: {
    rules: function() {
      const rules = [
        (v) => !!v || "Preenchimento obrigatório",
        (v) => (v && v >= this.caminhao.kmlast) || `Kilómetros devem ser superiores a ${this.caminhao.kmlast}`
      ];
      return rules
    }
  },
  watch: {
    readyToGo: function() {
      this.setObjeto("contentors", this.contentors);
      this.setObjeto("circuito", this.circuito);

      this.km_inicial.data_registro = new Date();
      var hst = {
        caminhao: this.caminhao,
        user: this.getObjeto("user"),
        rota: this.getObjeto("rotaSelecionada")
        };
      this.$http.post("historicocircuitos/", hst, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }).then((response) => {
        this.setObjeto("historicocircuitos", response.data.id);
        this.km_inicial.historicocircuito = response.data.id;
        this.$http
          .post("kms/", this.km_inicial, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          })
          .then((response) => {
            this.setObjeto("kmsInit", response.data.km);
            //readyToGo = true;
          })
          .catch((error) => {
            // Handle error.
            console.log("An error occurred:", error);
          });
      }).catch((error) => {
        // Handle error.
        console.log("An error occurred:", error);
      });
      this.$router.push("/contentores");
    }
  }
};
</script>

<style scoped>
  .v-main {
    padding-top: 0px !important;
  }
</style>